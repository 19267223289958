import React from 'react';
import careersImage from '../../../images/career.jpg';

const bannerStyle = {
    background: `url(${careersImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed', // Fix the background image
    height: '50vh'
};

const contentStyle = {
  position: 'relative',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  backgroundColor: 'rgba(245, 235, 224, 0.6)',
  padding: '20px',
};

const Careers = () => {
  return (
    <div>
    <div style={bannerStyle}></div>

      <section >
        <div className="container" >
          <div >
            <div style={contentStyle} className="container text-center">
              <h1 className="display-4">Join Our Team</h1>
              <p>
                <a href="https://www.linkedin.com/company/kurunzi-tech-media/" className="btn btn-primary btn-lg">Discover More</a>
              </p>
              <p>
                Explore our current job openings, learn about our company culture, and discover how you can be part of a dynamic team that's shaping the future. Join us in making a difference, driving innovation, and achieving your career aspirations. Your future starts here!.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Careers;
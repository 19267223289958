import React, { useState } from "react";
import { Link } from 'react-router-dom';
import drone from '../../../images/dronesky.jpg';

const Photography = () => {
  const backgroundImageStyle = {
    backgroundImage: `url("https://images.pexels.com/photos/248519/pexels-photo-248519.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")`,
    padding: "20px",
    minHeight: "40vh",
    backgroundAttachment: "fixed",
    width: "100%",
  };

  const cardStyle = {
    width: '300px',
    height: '350px',
    margin: '10px'
  };

  const imgStyle = {
    height: '200px',
    width: '100%',
    objectFit: 'cover'
  };
  return (
    <>
      <div style={backgroundImageStyle}>
        <div style={{ textAlign: "center", color: "white" }}>
          <h4 style={{ fontSize: "26px", marginTop: "100px" }}>
            <strong>Where visions meet reality through lens</strong>
          </h4>
        </div>
      </div>
      <section
        style={{
          backgroundColor: "#D9D9D9",
          fontFamily: "Arial, Helvetica, sans-serif,sans-serif",
          fontSize: "20px",
          alignItems:"center",
          justifyContent:"center",
        }}
      >
        
      <div className="container" style={{textAlign: 'center'}}>
      <h1>Photography and Videography Services!</h1>
      <hr className="my-3" />
      <div className="d-flex flex-wrap justify-content-center">
        <div className="card" style={cardStyle}>
          <img
            src="https://img.freepik.com/free-photo/modern-equipped-computer-lab_23-2149241213.jpg?t=st=1712757914~exp=1712761514~hmac=7935a0729ead745460b8c353a0e49e464d613e2c0b1f39f447032e6e35065c90&w=996"
            className="card-img-top"
            alt="Corporate Photography"
            style={imgStyle}
          />
          <div className="card-body">
            <p className="card-text">Corporate Photography</p>
            <Link to="/servicerates" className="btn btn-primary">Learn More</Link>
          </div>
        </div>
        <div className="card" style={cardStyle}>
          <img
            src="https://cdn.pixabay.com/photo/2022/01/10/04/37/event-6927353_1280.jpg"
            className="card-img-top"
            alt="Events Photography"
            style={imgStyle}
          />
          <div className="card-body">
            <p className="card-text">Events Photography</p>
            <Link to="/servicerates" className="btn btn-primary">Learn More</Link>
          </div>
        </div>
        <div className="card" style={cardStyle}>
          <img
            src="https://img.freepik.com/free-photo/medium-shot-women-working-together_23-2150506065.jpg?t=st=1712757392~exp=1712760992~hmac=da82cc9f2147e4478859b5b8acee7fc295c6a9bf1933bab1680ffe5394a5b24e&w=996"
            className="card-img-top"
            alt="Video Marketing"
            style={imgStyle}
          />
          <div className="card-body">
            <p className="card-text">Video Marketing</p>
            <Link to="/servicerates" className="btn btn-primary">Learn More</Link>
          </div>
        </div>
        <div className="card" style={cardStyle}>
          <img
            src={drone}
            className="card-img-top"
            alt="drone"
            style={imgStyle}
          />
          <div className="card-body">
            <p className="card-text">Drone Services</p>
            <Link to="/servicerates" className="btn btn-primary">Learn More</Link>
          </div>
        </div>
        <div className="card" style={cardStyle}>
          <img
            src="https://images.unsplash.com/photo-1497015289639-54688650d173?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            className="card-img-top"
            alt="Video Production"
            style={imgStyle}
          />
          <div className="card-body">
            <p className="card-text">Video Production</p>
            <Link to="/servicerates" className="btn btn-primary">Learn More</Link>
          </div>
        </div>
        <div className="card" style={cardStyle}>
          <img
            src="https://img.freepik.com/free-photo/side-view-photographer-taking-wedding-photos_23-2150264548.jpg?t=st=1712757709~exp=1712761309~hmac=27b884ccc84725eb23aba6c7d2a287b2be5f22771c6c1c1407e7fad18bbc1941&w=996"
            className="card-img-top"
            alt="Wedding Photography"
            style={imgStyle}
          />
          <div className="card-body">
            <p className="card-text">Wedding Photography</p>
            <Link to="/servicerates" className="btn btn-primary">Learn More</Link>
          </div>
        </div>
        <div className="card" style={cardStyle}>
          <img
            src="https://img.freepik.com/free-photo/pineapple-chipotle-salsa_53876-30488.jpg?t=st=1712757041~exp=1712760641~hmac=cc38ddc7a94828eff4e9493c79131bf1c166ffccd1564b1257e55841a4de6ade&w=996"
            className="card-img-top"
            alt="Product Photography"
            style={imgStyle}
          />
          <div className="card-body">
            <p className="card-text">Product Photography</p>
            <Link to="/servicerates" className="btn btn-primary">Learn More</Link>
          </div>
        </div>
      </div>
      </div>
      </section>
    </>
  )
};

export default Photography;

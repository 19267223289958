import React from 'react';
import pheny from '../../images/pheny.jpeg';
import moses from '../../images/moses.jpeg';
import Tokiti from '../../images/tokiti.jpg';


function About() {
  const teamMembers = [
    {
      name: 'Loice Phenny Adhiambo',
      image: { src: pheny },
      description: 'CEO',
      socialLinks: {
        facebook: 'https://www.facebook.com/phenny.loise',
        instagram: 'https://www.instagram.com/loise_phenny/',
        linkedin: 'https://www.linkedin.com/in/loise-adhiambo-7977b4189/?originalSubdomain=ke',
        email: 'mailto:pladhiambo@gmail.com',
      },
    },
    {
      name: 'Peter Gathogo',
      image: 'https://media.istockphoto.com/id/522855255/vector/male-profile-flat-blue-simple-icon-with-long-shadow.jpg?s=612x612&w=0&k=20&c=EQa9pV1fZEGfGCW_aEK5X_Gyob8YuRcOYCYZeuBzztM=',
      description: 'ICT Technician and Online Marketer',
      socialLinks: {
        facebook: 'https://web.facebook.com/man.pie.568',
        instagram: '',
        linkedin: '',
        email: 'mailto:peternjeri0888@gmail.com',
      },
    },
    {
      name: "Antony Gituuro",
      image: 'https://media.istockphoto.com/id/522855255/vector/male-profile-flat-blue-simple-icon-with-long-shadow.jpg?s=612x612&w=0&k=20&c=EQa9pV1fZEGfGCW_aEK5X_Gyob8YuRcOYCYZeuBzztM=',
      description: 'ICT technician',
      socialLinks: {
        facebook: 'https://www.facebook.com/',
        instagram: 'https://www.instagram.com',
        linkedin: 'https://www.linkein.com/',
        email: 'mailto:antonygituuro@gmail.com',
      },
    },

    {
      name: "Moses Githinji",
      image: { src: moses},
      description: 'Engineer',
      socialLinks: {
        facebook: 'https://web.facebook.com/mosesgithinjii',
        instagram: '',
        linkedin: 'https://plus.google.com/',
        email: 'mailto:mosesgithinji@gmail.com',
      },
    },

    {
      name: "Sharon Tokiti",
      image: { src: Tokiti},
      description: 'Software Developer',
      socialLinks: {
        facebook: 'https://www.facebook.com/sharontokitimshekero',
        instagram: '',
        linkedin: 'https://plus.google.com/',
        email: 'mailto:sharontokiti@gmail.com',
      },
    },
  ];

  return (
    <div>
      <div className="container ">
        <div className="row flex-wrap">
          <h1 className="text-center">Who we are and what we do</h1>
          <hr />
          <div className="mt-2 lead text-center">
            <p>
              At Kurunzi Tech Media, our commitment to delivering top-tier
              technology solutions is unwavering. We have built a reputation for
              providing high-quality hardware and software solutions that cater
              to the diverse needs of our clients. Our team of experienced
              professionals is dedicated to staying at the forefront of
              technological advancements, ensuring that we offer the latest and
              most reliable products and services.
            </p>

            <p>
              In addition to our core offerings, we take pride in our
              client-centric approach. We work closely with our valued customers
              to tailor our solutions to their specific requirements, ensuring
              that they receive personalized, efficient, and cost-effective
              technology solutions that drive their success.
            </p>

            <p>
              Kurunzi Tech Media is more than just a technology company; we are
              your strategic partner in the ever-evolving world of technology.
              With a focus on quality, innovation, and customer satisfaction, we
              are poised to continue setting new standards in the industry and
              helping our clients thrive in the digital age.
            </p>
          </div>
        </div>
      </div>

      <div className="container team-section p-4 mt-4">
  <div className="row flex-wrap">
    <div className="col-12 text-center">
      <h3>Our Team</h3>
    </div>
  </div>

  <div className="row flex-wrap">
    {teamMembers.map((member, index) => (
      <div key={index} className="col-md-4 col-sm-6 mb-3">
        <div className="card h-100 d-flex flex-column">
          <div className="d-flex justify-content-center align-items-center">
            <img
              src={member.image.src}
              alt="Profile"
              style={{
                width: '10vw',
                height: 'auto',
                borderRadius: '70%',
              }}
            />
          </div>
          <div className="card-body text-center flex-grow-1">
            <h5 className="card-title">{member.name}</h5>
            <p className="card-text">{member.description}</p>
          </div>
          <div className="card-footer">
            <div
              className="social-icons"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <a href={member.socialLinks.facebook} style={{ marginRight: '10px' }}>
                <i className="bi bi-facebook"></i>
              </a>
              <a href={member.socialLinks.instagram} style={{ marginRight: '10px' }}>
                <i className="bi bi-instagram"></i>
              </a>
              <a href={member.socialLinks.linkedin} style={{ marginRight: '10px' }}>
                <i className="bi bi-linkedin"></i>
              </a>
              <a href={member.socialLinks.email}>
                <i className="bi bi-envelope"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>

    </div>
  );
}

export default About;

import React from 'react';
import callIcon from '../../images/call.svg';

const Header = () => {


  return (
    <div className="container-fluid" style={{ backgroundColor: 'rgba(217, 217, 217, 1)' }}>
      <div className="row justify-content-between align-items-center py-1">
        <div className="col-md-6 col-sm-12 text-md-end text-center">
          <img src={callIcon} alt="Call Icon" className="text-white" />
          <a href="tel:+254706789734" className="text-dark text-decoration-none">
            +254706789734
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;

import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link } from 'react-router-dom';


const Footer = () => {
  const [isAtTop, setIsAtTop] = useState(true);

  // Event listener to track scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsAtTop(false);
      } else {
        setIsAtTop(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Add smooth scrolling effect
    });
  };

  const arrowStyle = {
    position: 'fixed',
            bottom: '20px',
            right: '20px',
            cursor: 'pointer',
            backgroundColor: 'orange',
            color: 'blue',
            borderRadius: '50%',
            padding: '12px',
            fontSize: '24px',          
          };

  return (
    <>
    <footer style={{ backgroundColor: 'rgba(128, 128, 128, 1)', color: 'white', padding: '20px' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <h2>Kurunzi Tech</h2>
            <p>
              Kurunzi Tech Media is a private limited, elite company that deals with laptops, desktops, accessories, software development, and photography.
            </p>
            <ul className="list-inline">
              <li className="list-inline-item"><Link to="https://web.facebook.com/p/Kurunzi-TECH-LTD-100066510652405/?_rdc=1&_rdr"><i className="bi bi-facebook" style={{ color: '#699BF7' }}></i></Link></li>
              <li className="list-inline-item"><Link to="https://twitter.com/kurunzitech"><i className="bi bi-twitter" style={{ color: '#699BF7' }}></i></Link></li>
              <li className="list-inline-item"><Link to="https://www.instagram.com/kurunzi_tech"><i className="bi bi-instagram" style={{ color: '#699BF7' }}></i></Link></li>
              <li className="list-inline-item"><Link to="https://www.tiktok.com/@kurunzitech"><i className="bi bi-tiktok" style={{ color: '#699BF7' }}></i></Link></li>
            </ul>
          </div>
          <div className="col-md-3">
            <h2>Quick Links</h2>
            <ul className="list-unstyled ">
              <li><Link to="/about" className='text-decoration-none text-light'>About Us</Link></li>
              <li><Link to="https://medium.com/@kurunzitechmedia" className='text-decoration-none text-light'>Blogs</Link></li>
              <li><Link to="/googlereviews" className='text-decoration-none text-light'>Testimonials</Link></li>
              <li><Link to="/careers" className='text-decoration-none text-light'>Careers <span className="badge" style={{ color: 'orange', fontFamily: 'Nanum Pen', fontSize:'20px' }}>we're hiring</span>
           </Link>
           </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h2>Services</h2>
            <ul className="list-unstyled">
              <li><Link to="/kurunzishop"  className='text-decoration-none text-light'>Laptops</Link></li>
              <li><Link to="/kurunzishop" className='text-decoration-none text-light'>Accessories</Link></li>
              <li><Link to="/repairs" className='text-decoration-none text-light'>Repairs</Link></li>
              <li><Link to="/webdevelopment"  className='text-decoration-none text-light'>Web Development</Link></li>
              <li><Link to="/termsofservice"className='text-decoration-none text-light' >Terms of service</Link></li>
             
            </ul>
          </div>
          <div className="col-md-3">
            <h2>Contact Us</h2>
            <ul className="list-unstyled">
              <li><Link to="mailto:kurunzitechmedia@gmail.com" style={{ color: 'white', textDecoration: 'none' }}><i className="bi bi-envelope" style={{ color: '#699BF7', marginRight: '5px' }}></i> kurunzitechmedia@gmail.com</Link></li>
              <li><Link to="tel:+254706789734" style={{ color: 'white', textDecoration: 'none' }}><i className="bi bi-phone" style={{ color: '#699BF7', marginRight: '5px' }}></i> +254 706 789 734</Link></li>
              <li><Link to="https://maps.app.goo.gl/299CxGWxULvM5rgA7" style={{ color: 'white', textDecoration: 'none' }}><i className="bi bi-geo-alt-fill" style={{ color: '#699BF7', marginRight: '5px' }}></i> Caxton Hse, Standard Street, 1st Floor, Room 3</Link></li>
              <li><Link to="https://maps.app.goo.gl/8JkLCTjbQkCM4Rgs8" style={{ color: 'white', textDecoration: 'none' }}><i className="bi bi-geo-alt-fill" style={{ color: '#699BF7', marginRight: '5px' }}></i> Rumwe Hse, Mfangano Street, Shukran Mall, Shop 6A</Link></li>
            </ul>
          </div>
        </div>       
      </div>
    </footer>
      {/* Scroll to top button */}
      {isAtTop ? null : (
        <div className="scroll-to-top" onClick={scrollToTop} style={arrowStyle}>
          <i className="bi bi-arrow-up"></i>
        </div>
      )}
    <div className="row ">
          <div className="col-md-12 ">
            <div className="legal-links d-flex bg-dark">
              <p className="mr-3" style={{ color:'white' ,marginLeft:'110px' ,marginTop:'7px'}}>Copyright &copy; {new Date().getFullYear()}. Kurunzi Tech Media.All Rights Reserved.</p>
            </div>
          </div>
        </div>
    </>
  );
};

export default Footer;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Note the import of Routes
import Header from './components/MainWebsite/Header';
 import Footer from './components/MainWebsite/Footer';
import Navbar from './components/MainWebsite/Navbar'
import Home from './components/MainWebsite/Home';
import About from './components/MainWebsite/About'
import Services from './components/MainWebsite/Services.jsx'
import ContactUs from './components/MainWebsite/Contact';
import TermsOfService from './components/MainWebsite/about pages/TermsOfService';
import FAQS from './components/MainWebsite/about pages/FAQS'
import Careers from './components/MainWebsite/about pages/Careers';
import GoogleReviews from './components/MainWebsite/about pages/GoogleReviews';
import WebDevelopment from './components/MainWebsite/services pages/webDevelopment';
import Kurunzishop from './components/MainWebsite/services pages/Kurunzishop'
import Merchandise from './components/MainWebsite/services pages/Merchandise'
import Repairs from './components/MainWebsite/services pages/Repairs'
import Photography from './components/MainWebsite/services pages/Photography';
import ShopItemDetail from './components/Ecommerce/ShopItemDetail';
import CartPage from './components/Ecommerce/CartPage';
import Checkout from './components/Ecommerce/Checkout';
import KurunziFiber from './components/MainWebsite/KurunziFiber';
const App = () => {
  return (
    <Router>
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Header />
        <Navbar />
        <Routes> 
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/termsofservice" element={<TermsOfService />} />
          <Route path="/faqs" element={<FAQS />} />
          <Route path='/careers' element={<Careers />} />
          <Route path="/googlereviews" element={<GoogleReviews />} />
          <Route path="/servicerates" element={<Services />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/webdevelopment" element={<WebDevelopment />} />
          <Route path="/Photography" element={<Photography />} />
          <Route path="/kurunzishop" element={<Kurunzishop />} />  
          <Route path="/merchandise" element={<Merchandise />} /> 
          <Route path="/repairs" element={<Repairs />} /> 
          <Route path="/cart" element={<CartPage/>}  />
          <Route path="/items/:id" element={<ShopItemDetail/>} />  
          <Route path='/checkout' element={<Checkout/>} /> 
          <Route path='/kurunzifiber' element={<KurunziFiber/>} />           
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
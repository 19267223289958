import React, {useState} from 'react';
import image from '../../../images/service.webp'

const backgroundImageStyle = {
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  color: 'white', 
  padding: '20px', 
  textAlign: 'center',
  backgroundAttachment: 'fixed', 
  height: '50vh'
};

const iconStyle = {
  fontSize: '48px',
  marginBottom: '10px',
};

const centerDivStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  backgroundColor: "#D9D9D9",
  padding: "20px",
}

const scrollToWebDesignProcess = () => {
  const element = document.getElementById('web-design-process');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll to the section
  }
};


const WebDevelopment = () => {
  const [formData, setFormData] = useState({ email: '', help: '', phone:'', name:'' });

const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    const response = await fetch('https://formspree.io/f/mleqondg', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      alert('Quote submitted successfully!');
      setFormData({ name: '', email: '', phone: '', help: '' }); // Reset form fields
    } else {
      alert('Quote submission failed. Please try again later.');
    }
  } catch (error) {
    console.error('Error submitting quote:', error);
    alert('Error submitting quote. Please try again.');
  }
};
const handleChange = (e) => {
  setFormData({ ...formData, [e.target.name]: e.target.value });
};

  return (
    <>
<div style={{ ...backgroundImageStyle, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <h1> Web Development Services</h1>
    <p>we will build you the best websites</p>
    <p>you've been imagining at the best of our ability.</p>
    <button onClick={scrollToWebDesignProcess}>OUR PROCESS</button>
    </div>
    
    <div className="web-development-container" style={{ backgroundColor: "#D9D9D9", padding: "20px",  alignItems: "center", justifyContent: "center", textAlign: "center" }}>
  

     
          {/* Centered Icons */}          
          <div style={centerDivStyle}>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <i className="bi bi-rocket" style={{ ...iconStyle, color: '#FF6347' }}></i>
              <h3>Cutting-Edge Web Development</h3>
              <p>Our team of skilled developers is at the forefront of technology, delivering web solutions that not only meet but exceed industry standards.</p>
            </div>

            <div className="col-md-4">
              <i className="bi bi-person" style={{ ...iconStyle, color: '#87CEEB' }}></i>
              <h3>Tailored to Your Needs</h3>
              <p>We understand that each project is unique. That's why we take a personalized approach to ensure your website reflects your brand's identity and goals.</p>
            </div>

            <div className="col-md-4">
              <i className="bi bi-lightbulb" style={{ ...iconStyle, color: '#FFD700' }}></i>
              <h3>Innovative Solutions</h3>
              <p>We don't just build websites; we create digital masterpieces. Our innovative solutions are designed to captivate your audience and drive meaningful engagement.</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <i className="bi bi-bar-chart" style={{ ...iconStyle, color: '#32CD32' }}></i>
              <h3>Results-Driven</h3>
              <p>Your success is our success. We are dedicated to delivering results that matter, whether it's boosting your online presence or increasing conversions.</p>
            </div>

            <div className="col-md-4">
              <i className="bi bi-people" style={{ ...iconStyle, color: '#FFA07A' }}></i>
              <h3>Client-Centric Approach</h3>
              <p>Our clients are at the heart of everything we do. We listen, we collaborate, and we deliver solutions that align with your vision.</p>
            </div>

            <div className="col-md-4">
              <i className="bi bi-gem" style={{ ...iconStyle, color: '#9370DB' }}></i>
              <h3>Unmatched Quality</h3>
              <p>We take pride in our attention to detail and commitment to quality. Your website will not only look stunning but will also function flawlessly.</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <i className="bi bi-lock" style={{ ...iconStyle, color: '#8A2BE2' }}></i>
              <h3>Security and Reliability</h3>
              <p>Your online security is our priority. We employ the latest security measures to ensure your website remains safe and reliable.</p>
            </div>

            <div className="col-md-4">
              <i className="bi bi-laptop" style={{ ...iconStyle, color: '#87CEFA' }}></i>
              <h3>Full-Service Solutions</h3>
              <p>From responsive web design to e-commerce development, we offer a comprehensive range of services to meet your every digital need.</p>
            </div>

            <div className="col-md-4">
              <i className="bi bi-phone" style={{ ...iconStyle, color: '#DC3545' }}></i>
              <h3>Mobile Optimization</h3>
              <p>We ensure your website is fully responsive and optimized for mobile devices, reaching a wider audience and enhancing user experience.</p>
            </div>            
          </div>
        </div>
      </div>
      <div id="web-design-process" style={centerDivStyle}>

  <h2>Our Web Design Process </h2>
  <p >We follow a systematic approach to create stunning websites that meet your goals and expectations. Our web design process includes the following steps:</p>
  <ol style={{ textAlign: "left" }}>
    <li><strong>Discovery:</strong> We start by understanding your business, goals, and target audience.</li>
    <li><strong>Planning:</strong> We create a detailed project plan, including site structure and design concepts.</li>
    <li><strong>Design:</strong> Our designers craft visually appealing layouts and user-friendly interfaces.</li>
    <li><strong>Development:</strong> Our developers bring the design to life, ensuring functionality and responsiveness.</li>
    <li><strong>Testing:</strong> We rigorously test the website to ensure it works flawlessly across devices and browsers.</li>
    <li><strong>Launch:</strong> After your approval, we launch the website to the public.</li>
    <li><strong>Optimization:</strong> We continuously monitor and improve the site's performance and user experience.</li>
    <li><strong>Maintenance:</strong> We provide ongoing support and updates to keep your website up-to-date.</li>
  </ol>
  <p>Our goal is to deliver a website that not only looks great but also helps you achieve your online objectives.</p>
</div>
   
    {/* Form */}
    <div className="col-md-6 mt-4 mt-md-0 bg-white p-4" style={{ boxShadow:'10px 10px 50px rgba(0, 0, 0, 0.6)', maxWidth:'500px',borderRadius:'10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>
  <form style={{ width: '100%' }} onSubmit={handleSubmit}>
    <div className="form-group">
      <h2>Free service quote</h2>
      <label htmlFor="name"></label>
      <input
        type="text"
        id="name"
        name="name"
        placeholder="Name*"
        value={formData.name}
        onChange={handleChange}
        style={{ ...inputStyle, boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)" }}
      />
    </div>
    <div className="form-group">
      <label htmlFor="email"></label>
      <input
        type="email"
        id="email"
        name="email"
        placeholder="Email*"
        value={formData.email}
        onChange={handleChange}
        style={{ ...inputStyle, boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)" }}
      />
    </div>
    <div className="form-group">
      <label htmlFor="phone"></label>
      <input
        type="tel"
        id="phone"
        name="phone"
        placeholder="Phone*"
        value={formData.phone}
        onChange={handleChange}
        style={{ ...inputStyle, boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)" }}
      />
    </div>
    <div className="form-group">
      <label htmlFor="help"></label>
      <textarea
        id="help"
        name="help"
        placeholder="How may we be of help to you? You can include your service budget..."
        value={formData.help}
        onChange={handleChange}
        style={{ ...textareaStyle, boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)" }}
      ></textarea>
    </div>
    <div className="text-center" style={{ width: '100%' }}>
      <button type="submit" className="btn btn-primary">
        Get Quote
      </button>
    </div>
  </form>
</div>    
</div>
    </>
  );
};

const inputStyle = {
  width: "100%",
  padding: "10px",
  marginBottom: "20px",
  borderRadius: "3px",
  border: "1px solid #ccc",
  fontSize: "16px",
  outline: "none",
};

const textareaStyle = {
  width: "100%",
  padding: "10px",
  marginBottom: "10px",
  borderRadius: "3px",
  border: "1px solid #ccc",
  fontSize: "16px",
  outline: "none",
  resize: "vertical",
};

export default WebDevelopment;

import React from 'react';
import blackT from '../../../images/tshirtblack.jpeg';
import blueT from '../../../images/tshirtblue.jpeg';
import whiteT from '../../../images/tshirtwhite.jpeg';
import hoods from '../../../images/hoods.jpeg';

const products = [
  {
    id: 1,
    name: 'White branded Tshirt',
    price: 1000,
    description: 'Kurunzi Branded Tshirts',
    image: whiteT,
  },
  {
    id: 2,
    name: 'Black branded Tshirt',
    price: 1000,
    description: 'Kurunzi Branded Tshirts',
    image: blackT,
  },
  {
    id: 3,
    name: 'Blue branded Tshirt',
    price: 1000,
    description: 'Kurunzi Branded Tshirts',
    image: blueT,
  },
  {
    id: 4,
    name: 'Black, Blue and White branded Hoods',
    price: 3000,
    description: 'Kurunzi Branded Hoods',
    image: hoods,
  },
];

function MerchandisePage() {
  const handleBuyNow = (product) => {
    // Replace with the actual WhatsApp URL for buy now
    window.location.href = `https://wa.me/+254706789734?text=I%20want%20to%20buy%20A%20${product.name}`;
  };

  return (
    <div className="container merchandise-page">
      <h1>Kurunzi Merchandise</h1>

      <div className="row product-list">
        {products.map((product) => (
          <div key={product.id} className="col-md-3 mb-4">
            <div className="card h-100">
              <img src={product.image} alt={product.name} className="card-img-top img-fluid" />
              <div className="card-body">
                <h2 className="card-title">{product.name}</h2>
                <p className="card-text">{product.description}</p>
                <p className="card-text">KSH{product.price}</p>
                <button className="btn btn-primary" onClick={() => handleBuyNow(product)}>
                  Buy Now on WhatsApp
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MerchandisePage;

import React from 'react';
import image from '../../../images/FAQ.jpg';

const FAQS = () => {

  const imageStyle = {
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed', // Fix the background image
    height: '50vh'
  };


  return (
   <div>
    <div style={imageStyle}></div>
      <section className='ms-5 me-5'>
        <h3 className="text-center mb-4 pb-2 text-primary fw-bold">FAQs</h3>
        <p className="text-center mb-5">
          Find the answers for the most frequently asked questions below
        </p>

        <div className="row">
          <div className="col-md-6 col-lg-4 mb-4">
            <h6 className="mb-3 text-primary"><i className="bi bi-send text-primary pe-2"></i> Are we open daily?</h6>
            <p>
              <strong><u>Absolutely!</u></strong> Our office doors open at 8am daily on weekdays and 9am on weekends except on Sundays.
            </p>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <h6 className="mb-3 text-primary"><i className="bi bi-truck text-primary pe-2"></i> Do you do deliveries and is it pay on delivery?</h6>
            <p>
              <strong><u>Yes, We deliver Countrywide!</u></strong> You can pay for your item on delivery after checking everything to your satisfaction.
            </p>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <h6 className="mb-3 text-primary"><i className="bi bi-laptop text-primary pe-2"></i> What kind of laptops do you sell?</h6>
            <p>
             We have both new and refurbished laptops. The refurbished ones are ExUk and are good as new.
            </p>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <h6 className="mb-3 text-primary"><i className="bi bi-rocket text-primary pe-2"></i>Do you do website maintenance?</h6>
            <p>
              Yes.We do.Our packages run monthly, semi-annually and annually .
            </p>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <h6 className="mb-3 text-primary"><i className="bi bi-browser-chrome text-primary pe-2"></i>How long does it take to develop a website? </h6>
            <p><strong><u>Approximately 2 weeks</u>.</strong>This entirely depends on the design, features and the content of the application. </p>
          </div>

          <div className="col-md-6 col-lg-4 mb-4">
            <h6 className="mb-3 text-primary"><i className="bi bi-check-square-fill text-primary pe-2"></i> What is your warranty policy?</h6>
            <p><strong><u>12months warranty</u>.</strong> Our warranty covers softwares, motherboard, battery, RAM and hard drive. <stong><u>NO WARRANTY</u></stong> for physical damages</p>
          </div>
        </div>
        </section>
        
  
    </div>


  );
};

export default FAQS;

import React, {useState} from 'react';
import {ValidationError } from '@formspree/react';
import image from '../../../images/repair.jpeg';

const containerStyle = {
  background: `url(${image}) no-repeat center center fixed`,
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '40vh',
};

const inputStyle = {
  width: '100%',
  padding: '10px',
  marginBottom: '20px',
  border: '1px solid #ccc',
  fontSize: '16px',
  outline: 'none',
  borderRadius: '10px',
};

const textareaStyle = {
  width: '100%',
  padding: '10px',
  marginBottom: '10px',
  border: '1px solid #ccc',
  fontSize: '16px',
  outline: 'none',
  resize: 'vertical',
  borderRadius: '10px',
};

const listItemStyle = {
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
};

const iconStyle = {
  marginRight: '10px',
  color:'#0D6EFD'
};

const Repairs = () => {
  const [formData, setFormData] = useState({ email: '', help: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch('https://formspree.io/f/mleqondg', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        alert('Quote submitted successfully!');
        setFormData({ name: '', email: '', phone: '', help: '' }); // Reset form fields
      } else {
        alert('Quote submission failed. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting quote:', error);
      alert('Error submitting quote. Please try again.');
    }
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <section style={{ backgroundColor: "#D9D9D9", textAlign: 'center' }}>
      <div style={{ ...containerStyle }}></div>
      
      <div className="container" style={{ backgroundColor: "#D9D9D9", fontFamily: 'Arial, Helvetica, sans-serif', fontSize: '20px' }}>
        <h1> Our Repair Services !</h1>
        <hr className="my-4" />
        <p>
          We provide the following computers, laptop and accessories repairs services and maintenance ⚙️⚒️
        </p>

        <ul style={{ listStylePosition: 'inside', paddingInlineStart: '0' }}>
          <li style={listItemStyle}>
            <i className="bi bi-laptop" style={iconStyle}></i>
            <span>Laptop Diagnostics</span>
          </li>
          <li style={listItemStyle}>
            <i className="bi bi-laptop" style={iconStyle}></i>
            <span>Laptop Servicing</span>
          </li>
          <li style={listItemStyle}>
            <i className="bi bi-motherboard" style={iconStyle}></i>
            <span>Motherboard repair</span>
          </li>
          <li style={listItemStyle}>
            <i className="bi bi-motherboard" style={iconStyle}></i>
            <span>Bios programming</span>
          </li>
          <li style={listItemStyle}>
            <i className="bi bi-fullscreen" style={iconStyle}></i>
            <span>Screen replacement</span>
          </li>
          <li style={listItemStyle}>
            <i className="bi bi-battery-full" style={iconStyle}></i>
            <span>Battery replacement</span>
          </li>
          <li style={listItemStyle}>
            <i className="bi bi-keyboard" style={iconStyle}></i>
            <span>Keyboard replacement</span>
          </li>
          <li style={listItemStyle}>
            <i className="bi bi-align-middle" style={iconStyle}></i>
            <span>Hinge and casing repair</span>
          </li>
          <li style={listItemStyle}>
            <i className="bi bi-memory" style={iconStyle}></i>
            <span>SSD, RAM and HDD upgrade</span>
          </li>
          <li style={listItemStyle}>
            <i className="bi bi-plugin" style={iconStyle}></i>
            <span>Software installation</span>
          </li>
        </ul>

        <div
          className="col-md-6 mt-4 mt-md-0 bg-white p-4 mx-auto"
          style={{
            maxWidth: '600px',
            boxShadow: '10px 10px 50px rgba(0, 0, 0, 0.6)',
            borderRadius: '10px',
          }}
        >
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <h2>Free service quote</h2>
              <label htmlFor="name"></label>
              <input
                type="text"
                name="name"
                placeholder="Name*"
                style={{
                  ...inputStyle,
                  boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
                }}
                value={formData.name}
                onChange={handleChange}
              />
               <ValidationError 
        prefix="Name" 
        field="name"
       
      />
            </div>
            <div className="form-group">
              <label htmlFor="email"></label>
              <input
                type="email"
                name="email"
                placeholder="Email*"
                value={formData.email}
                onChange={handleChange}
                style={{
                  ...inputStyle,
                  boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
                }}
              />
               <ValidationError 
        prefix="Email" 
        field="email"
       
      />
            </div>
            <div className="form-group">
              <label htmlFor="phone"></label>
              <input
                type="tel"
                name="phone"
                placeholder="Phone*"
                style={{
                  ...inputStyle,
                  boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
                }}
                value={formData.phone}
                onChange={handleChange}
              />
               <ValidationError 
        prefix="Phone" 
        field="phone"
      
      />
            </div>
            <div className="form-group">
              <label htmlFor="help"></label>
              <textarea
                name="help"
                placeholder="How may we be of help to you? You can include your service budget..."
                style={{
                  ...textareaStyle,
                  boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
                }}
                value={formData.help}
                onChange={handleChange}
              ></textarea>
              <ValidationError 
        prefix="Help" 
        field="help"
       
      />
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                Get Quote
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Repairs;
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoimage from "../../images/logo.png";
import './navbar.css';
import CartIcon from '../Ecommerce/CartIcon'; // Import the CartIcon component

const Navbar = () => {
  const [cartItemCount, setCartItemCount] = useState(0); // Initialize state for cart item count

  // You can modify this function to increase cart item count
  const addToCart = (item) => {
    setCartItemCount(prev => prev + 1); // Example function to simulate adding an item
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        {/* Logo */}
        <div className="navbar-brand">
          <a href="/">
            <img
              src={logoimage}
              alt="Logo"
              style={{
                height: 'auto', 
                maxWidth: '55px', 
                transform: 'scale(3.0)', 
              }}
            />
          </a>
        </div>

        {/* Navbar Toggler (Hamburger Icon) */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar Links */}
        <div className="collapse navbar-collapse justify-content-between" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Home
                <span className="underline"></span> {/* Underline element */}
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link to="/about" className="nav-link dropdown-toggle" id="aboutDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                About
                <span className="underline"></span> {/* Underline element */}
              </Link>
              <ul className="dropdown-menu">
                <li><Link to="/about" className="dropdown-item">Why Kurunzi</Link></li>
                <li><Link to="/termsofservice" className="dropdown-item">Terms of Service</Link></li>
                <li><Link to="/faqs" className="dropdown-item">FAQs</Link></li>
                <li><Link to="/googlereviews" className="dropdown-item">Google Reviews</Link></li>
                <li><Link to="/careers" className="dropdown-item">Careers</Link></li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <Link to="#" className="nav-link dropdown-toggle" id="servicesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Services
                <span className="underline"></span> {/* Underline element */}
              </Link>
              <ul className="dropdown-menu">
                <li><Link to="/webdevelopment" className="dropdown-item">Web Development</Link></li>
                <li><Link to="/photography" className="dropdown-item">Photography</Link></li>
                <li><Link to="/merchandise" className="dropdown-item">Merchandise</Link></li>
                <li><Link to="/repairs" className="dropdown-item">Repairs</Link></li>
                <li><Link to="/kurunzifiber" className="dropdown-item">Kurunzi Fiber</Link></li>
              </ul>
            </li>
            <li className="nav-item">
              <Link to="/kurunzishop" className="nav-link">Kurunzi Shop</Link>
            </li>
            <li className="nav-item">
              <Link to="/contactus" className="nav-link">Contact Us</Link>
            </li>
            <li className="nav-item">
              <CartIcon itemCount={cartItemCount} />
            </li>
          </ul>
          {/* Get Quote button */}
          <div className="ml-lg-auto">
            <Link to="/contactus" className="btn btn-primary">
              GET QUOTE
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import React, { useState, useEffect } from "react";
import service from "../images/service.svg";
import offices from "../images/offices.svg";
import customer from "../images/customer.svg";
import projects from "../images/projects.svg";
import './success.css'

const svgStyle = {
  width: '64px',
  height: '64px',
};

const cardContainerStyle = {
  backgroundColor: 'white',
  maxWidth: '1200px', 
  margin: 'auto', 
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  padding: '5px 20px',
  display: 'flex',
  justifyContent: "space-evenly",
};

const textContainerStyle = {
  textAlign: 'center',
  marginTop: '10px',
};

const svgTextStyle = {
  fontFamily: 'Inter',
  fontSize: '50px',
  fontWeight: '800',
  lineHeight: '54px',
  letterSpacing: '-1px',
  marginLeft:'-20px',
  color: 'orange',
};

const centerText = {
  textAlign: 'center',
  fontSize: '18px',
};

const buttonStyle = {
  backgroundColor: '#3869E3',
  color:'white',
  padding: '10px 20px',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  marginLeft: '650px',
  marginBottom: '0px',
};

const Success = () => {
  const [counter, setCounter] = useState(1);
  const [counter2, setCounter2] = useState(1);
  const [counter3, setCounter3] = useState(20);
  const [counter4, setCounter4] = useState(100);

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter < 10) {
        setCounter(counter + 1);
      }
    }, 300 / Math.abs(10 - counter));

    const interval2 = setInterval(() => {
      if (counter2 < 5) {
        setCounter2(counter2 + 1);
      }
    }, 300 / Math.abs(5 - counter2));

    const interval3 = setInterval(() => {
      if (counter3 < 96) {
        setCounter3(counter3 + 1);
      }
    }, 300 / Math.abs(96 - counter3));

    const interval4 = setInterval(() => {
      if (counter4 < 200) {
        setCounter4(counter4 + 1);
      }
    }, 300 / Math.abs(200 - counter4));

    if (counter === 1) {
      setCounter(2);
    }

    return () => {
      clearInterval(interval);
      clearInterval(interval2);
      clearInterval(interval3);
      clearInterval(interval4);
    };
  }, [counter, counter2, counter3, counter4]);

  return (
    <section>
      <div style={{ backgroundColor: '#9DB6EF',  paddingBottom:'40px',fontFamily: "Arial, Helvetica, sans-serif", fontSize: "18px"}}>
        <div style={{ textAlign: 'center' }}>
          <h2>Success in numbers</h2>
        </div>
        <div style={cardContainerStyle}>
          <div>
            <img src={service} alt="services" style={svgStyle} />
            <div style={textContainerStyle}>
              <p style={svgTextStyle}>{counter}+</p>
              <p style={centerText}>Years of Services</p>
            </div>
          </div>
          {/* <div>
            <img src={offices} alt="offices" style={svgStyle} /> */}
            {/* <div style={textContainerStyle}>
              <p style={svgTextStyle}>{counter2}+</p>
              <p style={centerText}>Country Offices</p>
            </div> */}
          {/* </div> */}
          <div>
            <img src={customer} alt="customer" style={svgStyle} />
            <div style={textContainerStyle}>
              <p style={svgTextStyle}>{counter3}%</p>
              <p style={centerText}>Customer Retention</p>
            </div>
          </div>
          <div>
            <img src={projects} alt="projects" style={svgStyle} />
            <div style={textContainerStyle}>
              <p style={svgTextStyle}>{counter4}+</p>
              <p style={centerText}>Projects</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Success;

import React from 'react';
import image from '../../../images/about.avif'

const TermsOfService = () => {
    
  const containerStyle = {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'roboto'
  };

  const sectionStyle = {
    marginBottom: '30px',
  };

  const headingStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#3B71CA',
  };

  const paragraphStyle = {
    fontSize: '16px',
    lineHeight: '1.5',
  };

  const imageContainerStyle = {
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed', // Fix the background image
    height: '40vh'

  };



  return (
    <>
 <div style={imageContainerStyle}></div>

    <div style={containerStyle}>
      <section style={sectionStyle}>
        <p style={paragraphStyle}>
          Welcome to <strong style={{color:'#3B71CA'}}>KURUNZI TECH MEDIA's LIMITED</strong> services. These terms and conditions govern your use of our website and the services we offer. By using our services, you agree to comply with and be bound by these terms.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}> Payment and Delivery Terms</h2>
        <p style={paragraphStyle}>
          Payment for our laptops and services is on delivery of the same.
        </p>
        <ul>
    <li>Countrywide delivery is available.</li>
    <li>We deliver overnight with Wells Fargo (fargo courier).</li>
    <li>Delivery around Nairobi is within 2 hours with our rider.</li>
    <li>If we use client's preferred means of delivery, they have to pay for the product in advance.</li>
    </ul>
      </section>
      <section style={sectionStyle}>
      <h2 style={headingStyle}> Data Privacy</h2>
      <p>
        Protecting your data is of utmost importance to us.!
"Our company is committed to protecting the personal information of our customers, employees, and any other individuals whose data we handle. We collect only the information necessary for providing our products/services and conducting business operations. This may include names, contact details, and payment information.

We use personal data responsibly and only for legitimate purposes, such as fulfilling orders,
      </p>
      </section>

      <section style={sectionStyle}>
      <h2 style={headingStyle}> Functionality</h2>
      <p>
        We strive to ensure that our websites and applications meet agreed-upon functionality requirements. Any additional features or changes may be subject to separate agreements and fees.
      </p>
      </section>

      <section style={sectionStyle}>
      <h2 style={headingStyle}> Copyright</h2>
      <p>
        All website content,designs, and services we provide are subject to copyright protection.
      </p>
      </section>

      <section style={sectionStyle}>
      <h2 style={headingStyle}>Compliance</h2>
      <p>
        We are committed to compliance with applicable laws and regulations. Please let us know if you have specific compliance requirements, and we will work with you to ensure adherence.
      </p>
      </section>
      <section style={sectionStyle}>
      <h2 style={headingStyle}>Changes/Revisions</h2>
      <p>
        We understand that projects may require changes and revisions. If need for a change or revision kindly, fill our quote form.
      </p>
      </section>  
    </div>
    </>
  );
};

export default TermsOfService;

import React, { useState, useEffect } from "react";
import ShopItemCard from "../../Ecommerce/ShopItemCard";
import Pagination from "../../Ecommerce/Pagination";
import { ReactComponent as SearchIcon } from '../../../images/search.svg'; // Correctly import the SVG
import './kurunzishop.css';

const Kurunzishop = () => {
  const [shopItems, setShopItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);  // State for loading indicator

  useEffect(() => {
    fetchCategories();
    fetchShopItems();
  }, []);

  useEffect(() => {
    fetchShopItems();
  }, [currentPage, searchQuery, selectedCategory]);

  const fetchCategories = () => {
    setIsLoading(true);  // Enable loading indicator
    fetch("https://kurunzitech-api-2d13e592f1a2.herokuapp.com/categories")
      .then((response) => response.json())
      .then((data) => {
        setCategories(data);
      })
      .catch((error) => console.error("Error fetching categories:", error))
      .finally(() => setIsLoading(false));  // Disable loading indicator
  };

  const fetchShopItems = () => {
    setIsLoading(true);  // Enable loading indicator
    let url = `https://kurunzitech-api-2d13e592f1a2.herokuapp.com/kurunzi_shop?page=${currentPage}`;
    if (searchQuery) {
      url += `&search=${encodeURIComponent(searchQuery)}`;
    }
    if (selectedCategory) {
      url += `&category_id=${selectedCategory}`;
    }

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setShopItems([...data.shop_items]);
        setTotalPages(data.total_pages);
      })
      .catch(error => {
        console.error("Error fetching items:", error);
      })
      .finally(() => setIsLoading(false));  // Disable loading indicator
  }

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    setCurrentPage(1); // Reset to page 1 when category changes
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on search
    fetchShopItems();
  };

  return (
    <div className="container">
     {isLoading ? (
  <div className="d-flex justify-content-center align-items-center" style={{ height: '10vh' }}>
      <span className="spinner-border" style={{ width: '4rem', height: '4rem',color:'orange'}} role="status" aria-hidden="true"></span>
   <span className="loader">Please Wait...</span>
  </div>
      ) : (
        <div className="row">
          <div className="col-md-4">
            <h3 className="categories-heading">Categories</h3>
            <div className="list-group">
              <button className="list-group-item list-group-item-action all-categories" onClick={() => handleCategoryChange(null)}>
                All Categories
              </button>
              {categories.map((category) => (
                <button key={category.id} className="list-group-item list-group-item-action category-button" onClick={() => handleCategoryChange(category.id)}>
                  {category.name}
                </button>
              ))}
            </div>
            <form className="search-box">
              <input
                type="text"
                placeholder="Search products..."
                className="form-control my-3"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <SearchIcon className="search-icon" onClick={handleSearch} />
            </form>
          </div>
          <div className="col-md-8">
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
            <div className="row">
              {shopItems.map(item => (
                <div className="col-md-6 mb-3" key={item.id}>
                  <ShopItemCard item={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Kurunzishop;

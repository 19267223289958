import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const Services = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    packageType: ""
  });

  const [formVisible, setFormVisible] = useState({ silver: false, gold: false, platinum: false });

  const toggleForm = (packageType) => {
    setFormVisible(prevState => ({ ...prevState, [packageType]: !prevState[packageType] }));
    setFormData({ ...formData, packageType });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://formspree.io/f/mleqondg", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Quote submitted successfully!");
        setFormData({ name: "", email: "", phone: "", packageType: "" }); // Reset form fields
      } else {
        alert("Quote submission failed. Please try again later.");
      }
    } catch (error) {
      console.error("Error submitting quote:", error);
      alert("Error submitting quote. Please try again.");
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const cardStyle = {
    width: '300px',
    height: 'auto',
    margin: '10px',
    textAlign: 'left'
  };

  const imgStyle = {
    height: '200px',
    width: '100%',
    objectFit: 'cover'
  };

  const renderForm = (packageType) => (
    <div className="form-container">
      <h5>Book Your {packageType.charAt(0).toUpperCase() + packageType.slice(1)} Session</h5>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor={`name-${packageType}`}>Name</label>
          <input
            type="text"
            className="form-control"
            id={`name-${packageType}`}
            name="name"
            placeholder="Enter your name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor={`email-${packageType}`}>Email</label>
          <input
            type="email"
            className="form-control"
            id={`email-${packageType}`}
            name="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor={`phone-${packageType}`}>Phone</label>
          <input
            type="tel"
            className="form-control"
            id={`phone-${packageType}`}
            name="phone"
            placeholder="Enter your phone number"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="text-center" style={{ width: "100%" }}>
          <button type="submit" className="btn btn-primary">Submit</button>
        </div>
      </form>
    </div>
  );

  return (
    <section  style={{ backgroundColor: "#D9D9D9", fontFamily: 'Arial, Helvetica, sans-serif', alignItems: 'center', justifyContent: 'center', textAlign: 'center', fontSize: '20px' }}>
    <div className="container" >
      <h1>Photography and Videography Packages</h1>
      <hr className="my-4" />
      <div className="d-flex flex-wrap justify-content-center">
        <div className="card" style={cardStyle}>
          <div className="card-body">
            <h5 className="card-title text-warning">Silver Package</h5>
            <h6 className="card-subtitle mb-2 text-muted">KSH 23,000</h6>
            <p className="card-text">
              <ul>
                <li>3 Hours (Minimum)</li>
                <li>20 Photos</li>
                <li>30 minutes video</li>
                <li>No editing</li>
              </ul>
            </p>
            <button onClick={() => toggleForm('silver')} className="btn btn-primary">Book Your Session Below</button>
            {formVisible.silver && renderForm('silver')}
          </div>
        </div>
        <div className="card" style={cardStyle}>
          <div className="card-body">
            <h5 className="card-title text-warning">Gold Package</h5>
            <h6 className="card-subtitle mb-2 text-muted">KSH 34,000</h6>
            <p className="card-text">
              <ul>
                <li>6 Hours</li>
                <li>Unlimited Photos</li>
                <li>3 Hours Videos</li>
                <li>Photo Editing</li>
              </ul>
            </p>
            <button onClick={() => toggleForm('gold')} className="btn btn-primary">Book Your Session Below</button>
            {formVisible.gold && renderForm('gold')}
          </div>
        </div>
        <div className="card" style={cardStyle}>
          <div className="card-body">
            <h5 className="card-title text-warning">Platinum Package</h5>
            <h6 className="card-subtitle mb-2 text-muted">KSH 45,000</h6>
            <p className="card-text">
              <ul>
                <li>12 Hours</li>
                <li>Unlimited Photos</li>
                <li>Unlimited Videos</li>
                <li>Editing of Videos and Photos</li>
              </ul>
            </p>
            <button onClick={() => toggleForm('platinum')} className="btn btn-primary">Book Your Session Below</button>
            {formVisible.platinum && renderForm('platinum')}
          </div>
        </div>
      </div>
      <Link to='/photography'><button className='btn' style={{backgroundColor:'orange', color:'black'}}>Back to services</button></Link>
        
    </div>
  </section>
  );
};

export default Services;
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import image1 from "../../images/image1.jpg";
import image5 from "../../images/photography.jpg";
import image8 from "../../images/fibrecables.jpg";
import image4 from "../../images/image4.jpg";
import WhereYouBelong from "../../home sections/belong";
import Services from "../../home sections/services";
import Success from "../../home sections/success";
// import Blog from "../../home sections/blogs";
import Typewriter from "./services pages/TypeWritter";

const imageStyle = {
  height: "50vh",
  objectFit: 'cover',
  overflow:'hidden',
  backgroundSize:'cover'
};

const captionStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  background: "rgba(0, 0, 0, 0.7)",
  color: "white",
  padding: "10px",
  transform: "translate(-50%, -50%)",
  maxWidth: "80%",
  textAlign: "center",
};

// Define a class for reduced brightness
const reducedBrightness = {
  filter: "brightness(0.7)", // Adjust the value as needed (0.7 reduces brightness to 70%)
};

const Home = () => {
  return (
    <div>
      <Carousel
        autoPlay
        interval={5000}
        infiniteLoop={true}
        showArrows={true}
        showStatus={false}
        showThumbs={false}
      >
         <div>
          <img src={image8} alt="Image 8" style={{ ...imageStyle, ...reducedBrightness }} />
          <div style={captionStyle}>
            <Typewriter text="KURUNZI FIBER_." speed={50} />
            <p>Internet that moves at the speed of life!</p>
          </div>
        </div>
        <div>
          <img src={image1} alt="Image 1" style={{ ...imageStyle, ...reducedBrightness }} />
          <div style={captionStyle}>
            <Typewriter text="WEB DEVELOPMENT_." speed={50} />
            <p>Your partner for stunning web solutions: creativity, technology, excellence. Join us for online success today!</p>
          </div>
        </div>

        <div>
          <img src={image4} alt="Image 4" style={{ ...imageStyle, ...reducedBrightness }} />
          <div style={captionStyle}>
            <Typewriter text="KURUNZI SHOP_." speed={50} />
            <p>Wealth of curated products awaits, where we welcome you to explore, shop, and discover unique treasures at Kurunzi</p>
          </div>
        </div>

        <div>
          <img src={image5} alt="Image 5" style={{ ...imageStyle, ...reducedBrightness }} />
          <div style={captionStyle}>
            <Typewriter text="PHOTOGRAPHY AND VIDEOGRAPHY_." speed={50} />
            <p>Excellence in capturing moments and illuminating memories .</p>
          </div>
        </div>
        {/* <div>
          <img src={image8} alt="Image 8" style={{ ...imageStyle, ...reducedBrightness }} />
          <div style={captionStyle}>
            <Typewriter text="GRAPHICS AND DESIGN_." speed={50} />
            <p>We wield creativity, expertise, and imagination to craft visually stunning, impactful designs that elevate your brand's identity.</p>
          </div>
        </div> */}
       
      </Carousel>

      <WhereYouBelong />
      <Services />
      <Success />
      {/* <Blog /> */}
    </div>
  );
};

export default Home;

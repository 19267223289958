import React, { useState, useEffect } from "react";

const Typewriter = ({ text, speed }) => {
  const [typedText, setTypedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const typingInterval = setInterval(() => {
      if (currentIndex < text.length) {
        setTypedText((prevText) => prevText + text.charAt(currentIndex));
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(typingInterval);
      }
    }, speed); // Adjust the typing speed as needed

    return () => {
      clearInterval(typingInterval);
    };
  }, [text, currentIndex]);

  return <h2>{typedText}</h2>;
};

export default Typewriter;

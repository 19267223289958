import React from 'react';
import serviceImage from "../images/services.jpg";
import repair from "../images/test.svg"
import arrow from "../images/arrow.svg";
import arrowBlue from "../images/arrowblue.svg";
import web from "../images/web.svg";
import laptop from "../images/laptop.svg";
import { Link } from 'react-router-dom';

const Services = () => {
  return (
    <div>
      {/* Our Services section */}
      <section style={{ position: "relative", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "18px" }}>
        <div
          style={{
            backgroundImage: `url(${serviceImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            filter: "blur(px)", 
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1
          }}
        ></div>
        <div className="container" style={{ textAlign: 'center' }}>
          <h2>Our Services</h2>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '100px',
            flexWrap: 'wrap',
            marginTop:'-5px'
          }}
        >
          <div class="card" style={{ justifyContent: 'center', width: '278px', padding: '15px', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
            <img src={web} alt="Web Development" />
            <div>
              <h5>Web Development</h5>
              <p>We excel in creating web applications that empower businesses to establish a strong online presence.</p>
              < Link to="/webdevelopment" style={{ textDecoration: 'none', color: '#3869E3' }}>
                Explore <img src={arrowBlue} alt="Explore" />
              </Link>
            </div>
          </div>

          <div class="card" style={{ justifyContent: 'center', width: '278px', padding: '15px', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
            <img src={laptop} alt="App Development" />
            <div>
              <h5>Kurunzi Shop</h5>
              <p>We have a wide range of laptops, desktops and accessories at your budget.</p>
              <Link to="/kurunzishop" style={{ textDecoration: 'none', color: '#3869E3' }}>
                Explore <img src={arrowBlue} alt="Explore" />
              </Link>
            </div>
          </div>

          <div class="card" style={{ justifyContent: 'center', width: '278px', padding: '15px', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
            <img src={repair} alt="Shopping Cart" />
            <div>
              <h5>Repairs</h5>
              <p>We provide excellence repair services and troubleshooting.</p>
              <Link to="/repairs" style={{ textDecoration: 'none', color: '#3869E3' }}>
                Explore <img src={arrowBlue} alt="Explore" />
              </Link>
            </div>
          </div>
        
        </div>
      </section>
    </div>
  );
};

export default Services;

import React from 'react';
import backgroundImage from '../../../images/back.jpeg';
import './GoogleReviews.css';

const GoogleReviews = () => {
  const containerStyle = {
    background: `url(${backgroundImage}) no-repeat center center fixed`,
    backgroundSize: 'cover',
  };

  const textStyles = {
    color: 'white',
    fontSize: '20px',
  fontFamily:'Arial, Helvetica, sans-serif,sans-serif;',
    textDecoration:'none'
  };

  const reviewData = [
    {
      name: 'Joe Kim',
      avatarUrl: 'https://lh3.googleusercontent.com/a/ACg8ocIcPUodFz97995c1JCvRxa_5FNBD85Oze2qyS6xdMc2=w60-h60-p-rp-mo-br100',
      date: '9 months ago',
      rating: 5,
      reviewText: 'The customer care services were excellent. Bought two laptops from their shop.',
      reviewLink: 'https://www.google.com/maps/contrib/101795687595472559362/reviews/@-1.2859453,36.817438,16z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US&entry=ttu',
    },
    {
      name: 'ERIC KIMATHI',
      avatarUrl: 'https://lh3.googleusercontent.com/a-/ALV-UjXPYqow8XeIzoxMR1v2mcuFXdkP9uIwUvN13wdfpQvmn2o=w60-h60-p-rp-mo-br100',
      reviewLink: 'https://www.google.com/maps/contrib/115517350007067933783/reviews/@-1.2859453,36.817438,16z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US&entry=ttu',
      date: '9 months ago',
      rating: 5,
      reviewText: 'Simply the best place ever to buy a laptop and the best customer service ever come across.',
    },
    {
      name: 'Pinnah Peris',
      avatarUrl: 'https://lh3.googleusercontent.com/a-/ALV-UjWoDSKyY4GhD70KwRkjzxkSGy4PI5yJLrKSmGxtfHmMbyo=w60-h60-p-rp-mo-br100',
      reviewLink: 'https://www.google.com/maps/contrib/105126234872074587276/reviews/@-1.2859399,36.817438,16z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US&entry=ttu',
      date: '9 months ago',
      rating: 5,
      reviewText: 'I purchased a Laptop and I received the best service. It was delivered to my office on time with all accessories.',
    },
    {
      name: 'Erastus Ngige',
      avatarUrl: 'https://lh3.googleusercontent.com/a-/ALV-UjWIF7BK1rkWBEqMH9Eth7mCV4y0t2BxI3jPgZSlcKvLi3M=w60-h60-p-rp-mo-br100',
      reviewLink: 'https://www.google.com/maps/contrib/105982075569155186103/reviews/@-1.2859399,36.817438,16z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US&entry=ttu',
      date: '10 months ago',
      rating: 5,
      reviewText: 'Best laptop deals in town, also they relate well with customers.',
    },
    {
      name: 'susan sargina',
      avatarUrl: 'https://lh3.googleusercontent.com/a-/ALV-UjWetZWld4Fp0L2entCxbgjJrpu71FT-VbAmhbH518Lyu2w=w60-h60-p-rp-mo-br100',
      reviewLink: 'https://www.google.com/maps/contrib/112087114951444431125/reviews/@-1.2859399,36.817438,16z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US&entry=ttu',
      date: '1 year ago',
      rating: 5,
      reviewText: 'Good customer service, bought an hp laptop last week and so far so good.',
    },
    {
      name: 'Allan Karani',
      avatarUrl: 'https://lh3.googleusercontent.com/a-/ALV-UjXwY1rKbERWcFSk7y1qNtTBqotG3wipkz8DPvTFsj22sw=w60-h60-p-rp-mo-br100',
      reviewLink: 'https://www.google.com/maps/contrib/115586886598664822528/reviews/@-1.2296268,36.7626388,11z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US&entry=ttu',
      date: '10 months ago',
      rating: 5,
      reviewText: 'For any Laptop related purchases, accessories, repair services, and inquiries at large, visit Kurunzi Tech Media. Transparency, customer-friendly prices, and quality are guaranteed. 🔥💯',
    },
    {
      name: 'Purity Muthoni',
      avatarUrl: 'https://lh3.googleusercontent.com/a-/ALV-UjUHGXtAF9uMIE_6q82rBYyD16jDFs9a3ftP5432l3HHRL0=w60-h60-p-rp-mo-br100',
      reviewLink: 'https://www.google.com/maps/contrib/102337018987037654545/reviews/@-2.3540675,36.0763175,7z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US&entry=ttu',
      date: '1 year ago',
      rating: 5,
      reviewText: 'Was great working with these professionals. Customer support at 💯.',
    },
    {
      name: 'Ahmed Mwenda',
      avatarUrl: 'https://lh3.googleusercontent.com/a-/ALV-UjUkuTCXXwCCwa0BIDjs5y3hV44bioOQA8rtk5TDPEW-vRs=w60-h60-p-rp-mo-ba3-br100',
      reviewUrl: 'https://www.google.com/maps/contrib/109934431422374581123/reviews/@-0.0515013,36.4576791,8z?hl=en-US&entry=ttu',
      date: '1 year ago',
      rating: 5,
      reviewText: 'Excellent customer service. Dedicated to meeting customer needs. Got what I wanted after viewing several products. I highly recommend them to anyone seeking computer tech services.',
    },
  ];

   // Set a fixed height for review cards
   const cardStyle = {
    height: '40vh', 
    backgroundColor: "#727072",
  };

  return (
    <section className="reviews" style={containerStyle}>
      <div className="container">
        <h2 style={textStyles}>Google Reviews</h2>
        <div className="row">
          {reviewData.map((review, index) => (
            <div className="col-md-6" key={index}>
              <div className="review" style={cardStyle}>
                <div className="review-info">
                  <img src={review.avatarUrl} alt={`Reviewer ${index + 1}`} />
                  <div className="reviewer-details">
                    <h4>
                      <a
                        className="review-name"
                        href={review.reviewLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {review.name}
                      </a>
                    </h4>
                    <p style={textStyles}>{review.date}</p>
                  </div>
                </div>
                <div className="review-rating">
                  {Array.from({ length: review.rating }, (_, i) => (
                    <span key={i} className="star">&#9733;</span>
                  ))}
                </div>
                <p className="review-text" style={textStyles}>
                  {review.reviewText}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="center-content">
          <h4>
            <a
              href="https://www.google.com/search?q=kurunzi+tech&oq=kurunzi+tech&aqs=chrome.0.35i39i650j46i175i199i512j69i64j0i22i30j69i60l3.4120j0j7&sourceid=chrome&ie=UTF-8#lrd=0x182f114f158d9f63:0x53093ba43f7c6a25,1,,,,"
              target="_blank"
              rel="noopener noreferrer"
              style={textStyles}
            >
              See All Reviews
            </a>
          </h4>
          <button className='button'>
          <a
            href="https://www.google.com/search?q=kurunzi+tech&oq=kurunzi+tech&aqs=chrome.0.35i39i650j46i175i199i512j69i64j0i22i30j69i60l3.4120j0j7&sourceid=chrome&ie=UTF-8#lrd=0x182f114f158d9f63:0x53093ba43f7c6a25,3,,,,"
            style={{ color: 'inherit', textDecoration: 'inherit' }}
          >
            WRITE A REVIEW ON GOOGLE
          </a>
          </button>
        </div>
      </div>
    </section>
  );
};

export default GoogleReviews;
import React from "react";
import test from "../images/test.svg";
import team from '../images/team.svg';
import arrow from '../images/arrow.svg';
import moneybag from "../images/moneybag.svg";
import "./belong.css"; 
import { Link } from "react-router-dom";

const WhereYouBelong = () => {
  return (
    <section className="p-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 col-xl-8 mx-auto">
            <div className="text-center">
              <h1>Welcome to where you belong !</h1>
              <hr className="my-4" />
            </div>
            <p>
              Kurunzi Tech Media is a distinguished private limited company
              specializing in a comprehensive range of cutting-edge technology
              solutions.
            </p>
            <p>
              <img src={test} alt="icon" />
              Our commitment to quality extends to both the laptops we sell and
              the software we develop. We vigorously test all our products and
              applications to ensure they meet the highest standards.
            </p>
            <p>
              <img src={team} alt="icon" />
              Our team comprises experienced professionals in both hardware and
              software solutions, ensuring you get comprehensive tech solutions.
            </p>
            <p>
              <img src={moneybag} alt="icon" />
              We believe that technology should be accessible to all; we offer
              competitive pricing on our laptops, desktops, accessories, and
              software services.
            </p>
            <div className="text-center">
              <Link to='/googlereviews' className="btn btn-primary mt-4" style={{ backgroundColor: "#3869E3"}}>
                Testimonials <img src={arrow} alt="Arrow" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhereYouBelong;

import React, { useRef } from 'react';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import availabilityImage from '../../images/fiberworker.jpg';
import fiberImage from '../../images/fibrecables.jpg';
import arrowBlue from '../../images/arrowblue.svg';
import './kurunzifibre.css';

// Sample data for fiber service plans
const fiberPlans = [
  {
    id: 1,
    name: 'Bronze',
    speed: '5 Mbps',
    price: '1,500',
    description: 'A basic plan for light internet usage.'
  },
  {
    id: 2,
    name: 'Silver',
    speed: '10 Mbps',
    price: '2,000',
    description: 'A plan for regular internet usage.'
  },
  {
    id: 3,
    name: 'Gold',
    speed: '15 Mbps',
    price: '2,500',
    description: 'A plan for moderate internet usage.'
  },
  {
    id: 4,
    name: 'Platinum',
    speed: '20 Mbps',
    price: '3,000',
    description: 'A plan for heavy internet usage.'
  },
  {
    id: 5,
    name: 'Diamond',
    speed: '30 Mbps',
    price: '4,000',
    description: 'A plan for very heavy internet usage.'
  },
  {
    id: 6,
    name: 'Titanium',
    speed: '50 Mbps',
    price: '5,000',
    description: 'A plan for extreme internet usage.'
  }
];

const KurunziFiber = () => {
  const plansRef = useRef(null);

  const scrollToPlans = () => {
    plansRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section style={{ backgroundColor: "#D7D6D6", fontFamily: 'Arial, Helvetica, sans-serif,sans-serif', fontSize: '20px' }}>
    <Container className="fiber-service">
      {/* First Section: Image on the left, description on the right */}
      <Row className="mb-5 align-items-center">
        <Col md={6}>
          <img
            src={fiberImage}
            alt="Fiber Internet"
            className="img-fluid"
            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
          />
        </Col>
        <Col md={6} className="d-flex flex-column justify-content-center">
          <h1>Experience Ultra-Fast Fiber Internet</h1>
          <p>Enjoy seamless streaming, gaming, and browsing with our reliable fiber internet plans. Choose the perfect package that suits your needs and stay connected with high-speed internet.</p>
          <Link to="#" onClick={scrollToPlans} style={{ textDecoration: 'none', color: '#3869E3' }}>
            Explore Packages <img src={arrowBlue} alt="Explore" />
          </Link>
        </Col>
      </Row>
     {/* Second Section: Image on the right, description on the left */}
      <Row className="mt-4 align-items-center">
        <Col md={6} className="d-flex flex-column justify-content-center">
        <h1>Fiber Availability</h1>
          <hr className='custom-hr'/>
          <p>We are expanding our 5G coverage fast, and soon we will have nation wide coverage.Our fiber internet service will be available in the following areas:</p>
          <ul>
            <li>Kitengela</li>
            <li>Isinya</li>
            <li>Athi River</li>
            <li>Kajiado</li>
          </ul>
        </Col>
        <Col md={6}>
          <img
            src={availabilityImage}
            alt="Fiber Availability"
            className="img-fluid"
            style={{ objectFit: 'cover', width: '100%', height: '55vh' }}
          />
        </Col>
      </Row>
      {/* Fiber Service Plans Section */}
      <div ref={plansRef}>
        <h1 className="text-center mb-4">Fiber Service Plans</h1>
        <hr className='custom-hr'/>
        <Row>
          {fiberPlans.map(plan => (
            <Col md={4} className="mb-4" key={plan.id}>
              <Card>
                <Card.Body>
                  <Card.Title>{plan.name} Plan</Card.Title>
                  <Card.Text>
                    <strong>Speed:</strong> {plan.speed}
                  </Card.Text>
                  <Card.Text>
                    <strong>Price:</strong> {plan.price}
                  </Card.Text>
                  <Card.Text>{plan.description}</Card.Text>
                  <Link to='/contactus'>
                    <Button variant="primary">
                    Get Connected
                    </Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </Container>
    </section>
  );
};

export default KurunziFiber;
